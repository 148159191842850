import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComingSoonPage from './pages/ComingSoonPage';
import VideoListPage from './pages/VideoListPage';
import VideoDetailPage from './pages/VideoDetailPage';
import DashboardPage from './pages/DashboardPage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SignupVerifyPage from './pages/SignupVerifyPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Helper function to check if user is logged in
export const isUserLoggedIn = () => {
  const token = localStorage.getItem('access_token');
  return !!token;
};

// Helper function to handle dashboard navigation
export const handleDashboardNavigation = (navigate) => {
  if (isUserLoggedIn()) {
    navigate('/dashboard');
  } else {
    navigate('/login', { state: { from: '/dashboard' } });
  }
};

function App() {
  const [videos, setVideos] = useState([]);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>CGA</title>
        </Helmet>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/imprint" element={<TermsOfService />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/contact" element={<ContactUsPage />} />
              <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
              <Route path="/videos" element={<ProtectedRoute><VideoListPage /></ProtectedRoute>} />
              <Route path="/video/:videoId" element={<ProtectedRoute><VideoDetailPage /></ProtectedRoute>} />
              <Route path="/signup-verify" element={<SignupVerifyPage />} />
            </Routes>
          </div>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
