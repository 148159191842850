import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { FaSignOutAlt, FaHome } from 'react-icons/fa';
import './VideoListPage.css';
import logo from './logo.png'; // Ensure you have a logo.png file

function VideoListPage() {
  const [videoGroups, setVideoGroups] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const username = localStorage.getItem('username'); // Add username from localStorage

  const handleLogout = async () => {
    const accessToken = localStorage.getItem('access_token'); // Get the access token

    try {
      // Send logout request
      const response = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/auth/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Logout failed! Status: ${response.status}`);
      }

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token'); // Clear refresh token
      localStorage.removeItem('username'); // Clear username
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      const accessToken = localStorage.getItem('access_token');

      try {
        // Fetch video groups
        const response = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/videos/all-groups', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setVideoGroups(data.video_groups); // Set video groups directly

        // Fetch signed URLs for each video
        const updatedGroups = await Promise.all(data.video_groups.map(async (group) => {
          const updatedVideos = await Promise.all(group.videos.map(async (video) => {
            const { videoUrl, thumbnailUrl } = await fetchSignedUrl(video.video_id);
            return { 
              ...video, 
              video_url: videoUrl, 
              thumbnail: thumbnailUrl || '' 
            };
          }));
          return { ...group, videos: updatedVideos }; // Return updated group with videos
        }));

        setVideoGroups(updatedGroups); // Update the state with the video groups
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos(); // Fetch videos on component mount
  }, []);

  const fetchSignedUrl = async (videoId) => {
    const accessToken = localStorage.getItem('access_token'); // Get the access token

    try {
      const response = await fetch(`https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/videos/generate-signed-url?video_id=${videoId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Include the access token in the header
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch signed URL! Status: ${response.status}`);
      }

      const data = await response.json();
      return { videoUrl: data.video_url, thumbnailUrl: data.thumbnail_url }; // Return both video_url and thumbnail_url
    } catch (error) {
      console.error('Error fetching signed URL:', error);
      return { videoUrl: null, thumbnailUrl: null }; // Return null if there's an error
    }
  };

  const handleVideoClick = async (videoId) => {
    navigate(`/video/${videoId}`);
  };

  return (
    <div className="futuristic-video-page">
      <header className="dashboard-header">
        <img src={logo} alt="Logo" className="dashboard-logo" onClick={() => navigate('/')} style={{cursor: 'pointer'}} />
        <nav className="nav-links">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Home
          </a>
          <a
            href="/about-us"
            onClick={(e) => {
              e.preventDefault();
              navigate("/about-us");
            }}
          >
            About Us
          </a>
          <a
            href="/faq"
            onClick={(e) => {
              e.preventDefault();
              navigate('/#faq');
            }}
          >
            FAQ
          </a>
          <a
            href="/dashboard"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard");
            }}
          >
            Dashboard
          </a>
          <a
            href="/contact"
            onClick={(e) => {
              e.preventDefault();
              navigate("/contact");
            }}
          >
            Contact Us
          </a>
        </nav>
        <div className="user-info">
          <span className="welcome-message">Welcome {username}</span>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      </header>

      <div className="video-list-container">
        <h1 className="page-title">Explore Our Educational Videos</h1>

        <div className="search-container">
          <input
            type="text"
            placeholder="Search videos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>

        <div className="custom-tabs">
          {videoGroups.map((group, index) => (
            <div
              key={group.video_group_id}
              className={`tab ${activeTab === index ? 'active' : ''}`}
              onClick={() => setActiveTab(index)}
            >
              {group.title}
            </div>
          ))}
        </div>

        <div className="video-list">
          {videoGroups[activeTab] && videoGroups[activeTab].videos.filter(video => 
            video.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            video.description.toLowerCase().includes(searchTerm.toLowerCase())
          ).map((video) => (
            <div key={video.video_id} className={`video-item ${video.title === 'Elite' || video.title === 'Premium' ? 'disabled' : ''}`} onClick={() => handleVideoClick(video.video_id)}>
              <img
                className="video-thumbnail"
                src={video.thumbnail} // Use the thumbnail URL
                alt={video.title}
              />
              <div className="video-info">
                <h2 className="video-title">{video.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideoListPage;
