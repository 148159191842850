import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { FaSignOutAlt, FaHome } from 'react-icons/fa';
import './VideoDetailPage.css';
import logo from './logo.png';
import { handleDashboardNavigation } from '../App';

function VideoDetailPage() {
  const { videoId } = useParams();
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videoGroups, setVideoGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideoDetails = async () => {
      const accessToken = localStorage.getItem('access_token');
      try {
        // Fetch video groups first
        const groupsResponse = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/videos/all-groups', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (groupsResponse.ok) {
          const groupsData = await groupsResponse.json();
          setVideoGroups(groupsData.video_groups);
          
          // Find the group containing the current video
          const group = groupsData.video_groups.find(g => 
            g.videos.some(v => v.video_id === videoId)
          );
          
          // Fetch signed URLs for all videos in the group
          if (group) {
            const updatedVideos = await Promise.all(group.videos.map(async (video) => {
              const urlResponse = await fetch(`https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/videos/generate-signed-url?video_id=${video.video_id}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${accessToken}`,
                },
              });
              
              if (urlResponse.ok) {
                const urlData = await urlResponse.json();
                return {
                  ...video,
                  video_url: urlData.video_url,
                  thumbnail_url: urlData.thumbnail_url
                };
              }
              return video;
            }));
            
            group.videos = updatedVideos;
            setCurrentGroup(group);
            
            // Set current video from the updated videos
            const currentVideo = updatedVideos.find(v => v.video_id === videoId);
            if (currentVideo) {
              setCurrentVideo(currentVideo);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching video details:', error);
      }
    };

    if (videoId) {
      fetchVideoDetails();
    }
  }, [videoId]);

  const handleRelatedVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  const handleLogout = async () => {
    const accessToken = localStorage.getItem('access_token');
    try {
      const response = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/auth/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Logout failed! Status: ${response.status}`);
      }

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('username');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Function to convert URLs into clickable links
  const linkifyText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Matches http(s) URLs
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div className="video-detail-container">
      <header className="top-bar">
        <img src={logo} alt="Logo" className="left-logo" onClick={() => navigate('/')} />
        <nav className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/about-us">About Us</Link>
          <a href="#faq" onClick={(e) => {
            e.preventDefault();
            navigate('/#faq');
          }}>FAQ</a>
          <a href="/dashboard" onClick={(e) => {
            e.preventDefault();
            handleDashboardNavigation(navigate);
          }}>Dashboard</a>
          <Link to="/videos">All Videos</Link>
          <Link to="/contact">Contact Us</Link>
        </nav>
        <Button
          className="logout-button"
          variant="contained"
          color="error"
          onClick={handleLogout}
          startIcon={<FaSignOutAlt />}
        >
          Logout
        </Button>
      </header>
      
      <div className="content">
        <div className="main-content">
          <div className="video-section">
            {currentVideo && (
              <video
                controls
                autoPlay
                className="video-player"
                key={currentVideo.video_url}
              >
                <source src={currentVideo.video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
          
          <div className="video-details">
            {currentVideo && (
              <>
                <h2 className="video-title">{currentVideo.title}</h2>
                <div className="video-description">
                  {currentVideo.description.split("\n").map((line, index) => (
                    <p key={index}>{linkifyText(line)}</p>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        
        <div className="related-videos">
          {currentGroup && (
            <>
              <h3 className="module-title">{currentGroup.title}</h3>
              <div className="video-list">
                {currentGroup.videos.map((video) => (
                  <div
                    key={video.video_id}
                    className={`related-video-item ${video.video_id === videoId ? 'active' : ''}`}
                    onClick={() => handleRelatedVideoClick(video.video_id)}
                  >
                    <div className="video-thumbnail">
                      {video.thumbnail_url && (
                        <img src={video.thumbnail_url} alt={video.title} />
                      )}
                    </div>
                    <div className="video-info">
                      <h4>{video.title || 'Untitled Video'}</h4>
                      <p className="video-description-preview">
                        {video.description?.slice(0, 100)}
                        {video.description?.length > 100 ? '...' : ''}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoDetailPage; 