import React, { useState, useEffect } from 'react'
import './popchat.css'
import ForumIcon from '@mui/icons-material/Forum'
import SendIcon from '@mui/icons-material/Send'
import { v4 as uuidv4 } from 'uuid'

export const PopChat = ( props ) => {
  const [chatOpen, setChatOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [chatId, setChatId] = useState('')
  const {messages, setMessages} = props

  useEffect(() => {
    setChatId(uuidv4())
  }, [])

  const toggleChat = () => {
    setChatOpen(!chatOpen)
  }

  const handleSend = async () => {
    if (inputValue.trim()) {
      setMessages((prevMessages) => [...prevMessages, inputValue])

      const payload = {
        chat_id: chatId,
        user_query: inputValue
      }

      try {
        console.log('Sending payload:', payload)
        setInputValue('')
        const response = await fetch('https://d38hw9x674y6i3.cloudfront.net/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })

        const data = await response.json()
        console.log('Response data:', data)

        if (data && data.response) {
          setMessages((prevMessages) => [...prevMessages, data.response])
          
        } else {
          setMessages((prevMessages) => [...prevMessages, "Error: No response from server"])
        }
      } catch (error) {
        console.error('Error sending message:', error)
        setMessages((prevMessages) => [...prevMessages, "Error: Unable to send message"])
      }

      // setInputValue('') Move this line to the top of the try block to make the input field clear immediately after sending the message
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend()
    }
  }

  // Function to format messages
  const formatMessage = (msg) => {
    // Replace **text** with <strong>text</strong>
    return msg.split(/\*\*(.*?)\*\*/g).map((part, index) => 
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
  };

  return (
    <div id='chatCon'>
      <div className="chat-box" style={{ display: chatOpen ? 'block' : 'none' }}>
        <div className="header">Chat with us</div>
        <div className="msg-area">
          {
            messages.map((msg, i) => (
              <p key={i} className={i % 2 ? 'right' : 'left'}>
                <span>{formatMessage(msg)}</span>
              </p>
            ))
          }
        </div>
        <div className="footer">
          <input 
            type="text" 
            value={inputValue} 
            onChange={(e) => setInputValue(e.target.value)} 
            onKeyDown={handleKeyPress}
            placeholder="Type a message..."
          />
          <button onClick={handleSend}>
            <SendIcon style={{ color: '#3F2182' }} />
          </button>
        </div>
      </div>
      <div className="pop" onClick={toggleChat}>
        <ForumIcon style={{ fontSize: 50, color: 'white', cursor: 'pointer' }} />
      </div>
    </div>
  )
}

export default PopChat