// src/components/ProtectedRoute.js
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('access_token');
  const location = useLocation();
  
  return token ? children : <Navigate to="/login" state={{ from: location.pathname }} />;
};

export default ProtectedRoute;
