import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SignupVerifyPage.css'; // Import the CSS file

function SignupVerifyPage() {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); // Extract the token from the URL

    const verifyEmail = async () => {
      try {
        const response = await fetch(`https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/users/signup-verify?token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Verification failed! Status: ${response.status}`);
        }

        const data = await response.json();
        setMessage(data.message || 'Your email ID is confirmed!'); // Set the confirmation message
      } catch (error) {
        setMessage('Error verifying your email. Please try again later.');
        console.error('Error during email verification:', error);
      } finally {
        setLoading(false); // Set loading to false after the request
      }
    };

    verifyEmail(); // Call the verification function
  }, [location]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state while verifying
  }

  return (
    <div className="signup-verify-container">
      <h1>{message}</h1>
      <button className="go-to-login-button" onClick={() => navigate('/login')}>
        Go to Login
      </button>
    </div>
  );
}

export default SignupVerifyPage; 