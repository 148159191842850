import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'; // for navigation and location
import { motion } from 'framer-motion';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import './LoginPage.css'; // Ensure this is updated with new styles
import logo from './logo.png'; // Make sure you have a logo.png file in the src directory

function LoginPage() {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSignUp, setIsSignUp] = useState(searchParams.get('signup') === 'true'); // Initialize based on URL param
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState('');

  // Load saved username and password from local storage
  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    if (savedUsername) setUsername(savedUsername);
    if (savedPassword) setPassword(savedPassword);
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    // Example: Password must be at least 8 characters long
    return password.length >= 8;
  };

  const validateUsername = (username) => {
    // Example: Username must be at least 3 characters long
    return username.length >= 3;
  };

  const handleSignUp = async () => {
    if (!validateUsername(name)) {
      setError('Username must be at least 3 characters long');
      return;
    }
    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long');
      return;
    }
    if (!validateEmail(email)) {
      setError('Invalid email format');
      return;
    }
    if (name.includes(' ')) {
      setError('Username should not contain spaces.');
      return;
    }

    // Replace with your API endpoint
    const response = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/users/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: name, // Use name as username
        password: password, // Collect password from user
        email: email
      }),
    });

    if (response.ok || response.status === 201) {
      setError(''); // Clear any existing error message
      setSuccessMessage('Signup successful! Please check your email to verify your account.'); // Set success message
      setIsSignUp(false); // Switch to login tab
    } else {
      const errorData = await response.json(); // Get the error message from the response
      setError(errorData.message || 'Sign up failed'); // Set the error message
    }
  };

  const handleLogin = async () => {
    // Temporary bypass for testing
    if (username === 'admin' && password === 'admin') {
        localStorage.setItem('access_token', 'dummy_access_token');
        localStorage.setItem('refresh_token', 'dummy_refresh_token');
        localStorage.setItem('username', username);
        const intendedDestination = location.state?.from || '/dashboard';
        navigate(intendedDestination);
        return;
    }

    const response = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('access_token', data.access_token.token);
      localStorage.setItem('refresh_token', data.refresh_token.token);
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
      const intendedDestination = location.state?.from || '/dashboard';
      navigate(intendedDestination);
    } else {
      setError('Invalid username or password');
    }
  };

  const [currentNews, setCurrentNews] = useState(0);
  const newsItems = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNews((prev) => (prev + 1) % newsItems.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      className="futuristic-login-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="logo-container">
        <img src={logo} alt="Logo" className="login-logo" onClick={() => navigate('/')} style={{cursor: 'pointer'}} />
      </div>

      {/* <div className="content-container">
        <div className="academy-description">
          <h2>Welcome to Concrete Gold Academy</h2>
          <p>Unlock the secrets of real estate investment and wealth creation with Concrete Gold Academy. Our expert-led courses and cutting-edge resources empower you to build a solid financial future through strategic property investments.</p>
        </div>
        <div className="news-ticker">
          <h3>Latest Updates</h3>
          <p>{newsItems[currentNews]}</p>
        </div>
      </div> */}

      <div className="futuristic-login-container">
        <div className="tab-container">
          <button className={`tab-button ${!isSignUp ? 'active' : ''}`} onClick={() => setIsSignUp(false)}>Login</button>
          <button className={`tab-button ${isSignUp ? 'active' : ''}`} onClick={() => setIsSignUp(true)}>Sign Up</button>
        </div>

        {isSignUp ? (
          <>
            <div className="input-group">
              <input 
                type="text" 
                placeholder="Username" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
              />
            </div>
            <div className="input-group">
              <input 
                type="email" 
                placeholder="Email ID" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
              />
            </div>
            <div className="input-group">
              <input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>} {/* Display success message */}
            <motion.button
              className="futuristic-login-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSignUp}
            >
              Sign Up
            </motion.button>
          </>
        ) : (
          <>
            <div className="input-group">
              <FaUserAlt className="input-icon" />
              <input 
                type="text" 
                placeholder="Username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
              />
            </div>
            <div className="input-group">
              <FaLock className="input-icon" />
              <input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>

            {error && <p className="error-message">{error}</p>}

            <motion.button
              className="futuristic-login-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleLogin}
            >
              Login
            </motion.button>
          </>
        )}
      </div>
    </motion.div>
  );
}

export default LoginPage;