import React, { useState } from 'react'
export const faqs = [
    {
      question: "How does CGA help me with my real estate investments?",
      answer: [
        {
          type: 'text',
          content: "Concrete Gold Academy's purpose is to equip you with all the necessary knowledge for your first real estate investment. Starting with the real estate basics that will help you to understand all the calculations, we will moreover teach you the whole process of buying, renting out, and maintaining a property transparently and with a lot of insights behind the scenes. In this way, you will be able to reduce your tax burden significantly and build up a passive income."
        }
      ]
    },
    {
      question: "What services does CGA offer?",
      answer: [
        {
          type: 'text',
          content: "As an online video academy, the Concrete Gold Academy primarily offers you a huge database of real estate-related knowledge in the form of educational videos.\n\nSince knowledge without action is useless, our team of real estate consultants is always available for personal advice and working out your individual investment strategy. This is what we can do for you:"
        },
        {
          type: 'list',
          items: [
            "Finding your investment property in Berlin, Leipzig, Nürnberg or Munich",
            "Accompanying you to the property viewing and notary appointment",
            "Developing your financing strategy and brokering your loan",
            "Maintaining and managing your property and your tenant",
            "Supporting you with the tax declaration"
          ]
        }
      ]
    },
    {
      question: "How much does a membership to CGA cost?",
      answer: [
        {
          type: 'text',
          content: "We have conceived the membership fees at a massively reduced rate to allow access for all. Our basic membership for €200 unlocks full access to our services which include the following:"
        },
        {
          type: 'list',
          items: [
            "Access to our premium videos",
            "Priority support",
            "Personalized strategy development",
            "Access to new features and updates",
            "Full access to the coursework"
          ]
        }
      ]
    },
    {
      question: "Can I buy real estate when I'm on a Blue Card visa?",
      answer: [
        {
          type: 'text',
          content: "Yes, you can buy real estate in Germany while holding a Blue Card visa. However, most banks only grant loans to permanent residents or citizens."
        },
        {
          type: 'text',
          content: "This means there is a lower count of banks that are willing to approve your financing request, leading to an up to 0.5% higher interest rate on your mortgage."
        },
        {
          type: 'text',
          content: "If your income is sufficient and your financing volume is adequate for your personal situation, then it is still possible to buy a property in Germany. The bank will request your Blue Card, the Zusatzblatt (green paper), and your passport."
        }
      ]
    },
    {
      question: "How much downpayment do I need?",
      answer: [
        {
          type: 'text',
          content: "To ensure the perfect balance between a low interest rate on your loan and a high return on equity on your property, we recommend 100% financing. This means that the loan amount corresponds to the purchase price, meaning there is no downpayment. You only pay for the additional purchase costs, which are usually 8% of the purchase price, out of your own pocket."
        },
        {
          type: 'example',
          content: "If you buy a property for €300,000 that means you have to invest €24,000 upfront."
        }
      ]
    },
    {
      question: "How do tax savings with real estate work?",
      answer: [
        {
          type: 'text',
          content: "Real estate can offer significant tax advantages, but only if you're buying for investment purposes. The idea is to lower your taxable income."
        },
        {
          type: 'text',
          content: "When you rent out your property, many associated costs can be deducted from your taxable rental income. These include loan interest, maintenance expenses, property management fees, and even depreciation on the building's value over time."
        },
        {
          type: 'example',
          content: "If your rental income is €15,000 per year and your deductible expenses amount to €18,000, your taxable income decreases by €3,000. At a 42% tax rate, this leads to €1,260 in tax savings, which is roughly €100 per month."
        },
        {
          type: 'text',
          content: "Do you want to save taxes with real estate? Just get in touch with us and we will help you."
        }
      ]
    },
    {
      question: "Which metrics are most important?",
      answer: [
        {
          type: 'definition',
          term: 'Rental Yield',
          description: 'This measures your annual cold rental income relative to the property\'s purchase price. A healthy rental yield is about 5%.'
        },
        {
          type: 'example',
          content: 'If you buy a property for €300,000 and the annual cold rent is €15,000, this means you have a 5% rental yield.'
        },
        {
          type: 'separator'
        },
        {
          type: 'definition',
          term: 'Cash Flow',
          description: 'The money left after all expenses and loan payments is called cash flow. Positive cash flow ensures the property generates passive income.'
        },
        {
          type: 'example',
          content: '€15,000 cold rent + €1,260 tax refund - 13,500€ financing costs - €1,800 operating costs = €960 positive cash flow'
        },
        {
          type: 'separator'
        },
        {
          type: 'definition',
          term: 'Loan-to-Value Ratio (LTV)',
          description: 'This represents the percentage of the property\'s value financed by a loan.'
        },
        {
          type: 'example',
          content: 'A 90% LTV means you\'re financing 90% of the property value and contributing 10% upfront. Please tell the property value assessed by the bank apart from the purchase price. A 90% LTV is different from a 90% financing!'
        },
        {
          type: 'separator'
        },
        {
          type: 'definition',
          term: 'Occupancy Rate',
          description: 'For rental properties, the percentage of time the property is rented out within a year is called the occupancy rate.'
        },
        {
          type: 'text',
          content: 'You can find the overall vacancy rates of Germany\'s major cities on the Internet. A vacancy rate below 4% (occupancy rate of 96%) is considered beneficial for a property investor because it guarantees an almost permanently occupied apartment.'
        }
      ]
    }
];
