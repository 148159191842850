import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './LandingPage.css';
import logo from './logo.png';
import bootcampImage from '../components/assets/images/lp2.jpg';
import linkedin1 from '../components/assets/images/linkedin1.jpg';
import linkedin2 from '../components/assets/images/linkedin2.jpg';
import linkedin3 from '../components/assets/images/linkedin3.jpg';
import PopChat from './popchat';
import { faqs } from './Questions';
import { handleDashboardNavigation } from '../App';

const LandingPage = () => {
  const navigate = useNavigate();
  const pricingRef = useRef(null);
  const questRef = useRef(null);
  const bootcampRef = useRef(null);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [messages, setMessages] = useState(["Hello! How can we help you today?"]);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (window.location.hash === '#faq' && questRef.current) {
      questRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleStartForFree = () => {
    navigate('/login?signup=true');
  };

  const scrollToQuestions = () => {
    questRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleQuestion = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  return (
    <div className="landing-page">
      <header>
        <img src={logo} alt="Logo" className="logo" />
        <nav className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/about-us">About Us</Link>
          <a href="#faq" onClick={(e) => {
            e.preventDefault();
            if (window.location.pathname === '/') {
              scrollToQuestions();
            } else {
              navigate('/#faq');
            }
          }}>FAQ</a>
          <a href="/dashboard" onClick={(e) => {
            e.preventDefault();
            handleDashboardNavigation(navigate);
          }}>Dashboard</a>
          <Link to="/contact">Contact Us</Link>
        </nav>
        <button className="sign-up-button" onClick={() => navigate('/login?signup=true')}>Sign up →</button>
      </header>
      <main>
        <section className="hero-section">
          <div className="content-container">
            <div className="main-text">Unlock the Power of Real Estate Investment with Hands-On Experience</div>
            <button className="start-for-free" onClick={handleStartForFree}>
              <span className="button-text">Start Free Trial</span>
              <span className="arrow">→</span>
            </button>
          </div>
        </section>

        <section className="bootcamp-section" ref={bootcampRef}>
          <div className="bootcamp-content">
            <div className="bootcamp-left">
              <div className="bootcamp-tag">#concretegold</div>
              <h2>THE ONLY REAL ESTATE BOOTCAMP</h2>
              <p>Our Real Estate Bootcamp is designed to give you a complete, practical education in real estate investing, consulting, and property management.</p>
              <button className="explore-courses" onClick={handleStartForFree}>
                Explore Courses
                <span className="arrow">→</span>
              </button>
            </div>
            <div className="bootcamp-right">
              <div className="image-container">
                <img src={bootcampImage} alt="Person typing on laptop" />
              </div>
            </div>
          </div>
        </section>

        <section className="features-section">
          <div className="features-wrapper">
            <h1 className="features-title">Features</h1>
            <div className="features-container">
              <div className="feature-box">
                <span className="feature-tag">#one</span>
                <h2 className="feature-heading">Calculation Toolkit</h2>
                <p className="feature-description">
                  Advanced real estate investment calculators for ROI analysis, mortgage payments, rental yield, and property appreciation projections to make data-driven decisions.
                </p>
              </div>
              <div className="feature-box">
                <span className="feature-tag">#two</span>
                <h2 className="feature-heading">Access to community and properties</h2>
                <p className="feature-description">
                  Connect with experienced investors, browse exclusive property listings, and participate in group discussions to share insights and investment opportunities.
                </p>
              </div>
              <div className="feature-box">
                <span className="feature-tag">#three</span>
                <h2 className="feature-heading">AI powered Chatbot</h2>
                <p className="feature-description">
                  24/7 AI-powered assistance for instant answers to your real estate queries, market analysis, and personalized investment strategy recommendations.
                </p>
              </div>
            </div>
            <button className="enroll-now-button" onClick={() => navigate('/login?signup=true')}>
              Enroll Now
              <span className="arrow">→</span>
            </button>
          </div>
        </section>

        <section className="blog-section">
          <h2 className="blog-title">Watch, Read & Stay Updated</h2>
          <div className="blog-container">
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7275902825706971136/?actorCompanyId=104952445" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="blog-card-link">
              <div className="blog-card">
                <h3>1600-YEAR-OLD Investment Strategy to beat the RECESSION today!</h3>
                <p>History shows that three key indicators have accurately predicted Germany's recessions—and all have now been triggered.</p>
                <div className="blog-image">
                  <img src={linkedin1} alt="Investment Strategy" />
                </div>
                <div className="blog-footer">
                  <span className="author">Loui Sera</span>
                  
                </div>
              </div>
            </a>

            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7275528952155144193/?actorCompanyId=104952445" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="blog-card-link">
              <div className="blog-card">
                <h3>Interior Design for Investment Properties</h3>
                <p>What colors should be used for the interior of an investment property?</p>
                <div className="blog-image">
                  <img src={linkedin2} alt="Interior Design" />
                </div>
                <div className="blog-footer">
                  <span className="author">Shreekara Kulkarni</span>
                  
                </div>
              </div>
            </a>

            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7273393917214048257/?actorCompanyId=104952445" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="blog-card-link">
              <div className="blog-card">
                <h3>It's only a matter of time before the market crashes!</h3>
                <p>Three key indicators have historically predicted Germany's recessions with high accuracy, and all have now been triggered.</p>
                <div className="blog-image">
                  <img src={linkedin3} alt="Market Analysis" />
                </div>
                <div className="blog-footer">
                  <span className="author">Loui Sera</span>
                  
                </div>
              </div>
            </a>
          </div>
          <div className="blog-cta">
            <a 
              href="https://www.linkedin.com/company/concrete-gold-academy/posts/?feedView=all"
              target="_blank"
              rel="noopener noreferrer"
              className="subscribe-button"
            >
              <span>Stay Connected with Us</span>
              <span className="arrow">→</span>
            </a>
          </div>
        </section>

        <section className="faq-section" id="faq" ref={questRef}>
          <div className="faq-content">
            <div className="faq-left">
              <h2>Frequently Asked Questions</h2>
              <p>Got more questions?</p>
              <button className="contact-us-button" onClick={() => navigate('/contact')}>
                Contact us now →
              </button>
            </div>
            <div className="faq-right">
              {faqs.map((faq, index) => (
                <div 
                  key={index} 
                  className={`faq-item ${expandedQuestion === index ? 'expanded' : ''}`}
                  onClick={() => toggleQuestion(index)}
                >
                  <div className="faq-question">
                    <h3>{faq.question}</h3>
                    <span className="toggle-icon">+</span>
                  </div>
                  <div className={`faq-answer ${expandedQuestion === index ? 'show' : ''}`}>
                    {typeof faq.answer === 'string' ? (
                      <p>{faq.answer}</p>
                    ) : (
                      <>
                        {faq.answer.map((section, idx) => {
                          if (section.type === 'text') {
                            return <p key={idx}>{section.content}</p>;
                          } else if (section.type === 'list') {
                            return (
                              <ul key={idx}>
                                {section.items.map((item, i) => (
                                  <li key={i}>{item}</li>
                                ))}
                              </ul>
                            );
                          } else if (section.type === 'example') {
                            return <div key={idx} className="example">{section.content}</div>;
                          } else if (section.type === 'calculation') {
                            return <span key={idx} className="calculation">{section.content}</span>;
                          } else if (section.type === 'definition') {
                            return (
                              <div key={idx}>
                                <span className="definition">{section.term}:</span>
                                {section.description}
                              </div>
                            );
                          } else if (section.type === 'separator') {
                            return <hr key={idx} className="separator" />;
                          }
                          return null;
                        })}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="pricing-section" ref={pricingRef} hidden>
          <div className="pricing-box">
            <h3>Free</h3>
            <ul>
              <li>Basic Features</li>
              <li>Community Support</li>
              <li>Limited Access</li>
            </ul>
            <button className="start-for-free" onClick={handleStartForFree}>
              <span className="button-text">Start for Free</span>
              <span className="arrow">→</span>
            </button>
          </div>
          <div className="pricing-box">
            <h3>Unlimited - 199 EUR</h3>
            <ul>
              <li>All Features</li>
              <li>Priority Support</li>
              <li>Unlimited Access</li>
            </ul>
            <button className="start-for-free" onClick={handleStartForFree}>
              <span className="button-text">Start for Free</span>
              <span className="arrow">→</span>
            </button>
          </div>
          <div className="pricing-box">
            <h3>Enterprise - 399 EUR</h3>
            <ul>
              <li>Custom Solutions</li>
              <li>Dedicated Support</li>
              <li>Enterprise Access</li>
            </ul>
            <button className="start-for-free" onClick={handleStartForFree}>
              <span className="button-text">Start for Free</span>
              <span className="arrow">→</span>
            </button>
          </div>
        </div>
      </main>
      <PopChat messages={messages} setMessages={setMessages} />
    </div>
  );
};

export default LandingPage;