import React from "react";
import { useNavigate } from "react-router-dom";
import "./AboutUsPage.css";
import logo from "./logo.png";
import background_image_1 from '../components/assets/images/1.png';
import background_image_2 from '../components/assets/images/2.png';
import background_image_3 from '../components/assets/images/3.png';
import background_image_4 from '../components/assets/images/4.png';
import portrait_Loui from '../components/assets/images/loui.jpeg';
import portrait_Shree from '../components/assets/images/shree.jpeg';
import portrait_hari from '../components/assets/images/hari.jpeg';
import { handleDashboardNavigation } from "../App";


function AboutUsPage() {
  const navigate = useNavigate();

  return (
    <div className="about-us-page">
      <header>
        <img
          src={logo}
          alt="Logo"
          className="logo"
          onClick={() => navigate("/")}
        />
        <nav className="nav-links">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Home
          </a>
          <a
            href="/about-us"
            onClick={(e) => {
              e.preventDefault();
              navigate("/about-us");
            }}
          >
            About Us
          </a>
          <a
            href="/faq"
            onClick={(e) => {
              e.preventDefault();
              navigate('/#faq');
            }}
          >
            FAQ
          </a>
          <a
            href="/dashboard"
            onClick={(e) => {
              e.preventDefault();
              handleDashboardNavigation(navigate);
            }}
          >
            Dashboard
          </a>
          <a
            href="/contact"
            onClick={(e) => {
              e.preventDefault();
              navigate("/contact");
            }}
          >
            Contact Us
          </a>
        </nav>
      </header>

      <main>
        <div className="about-content">
          <section className="welcome-section">
            <div className="section-content-welcome">
              <h1>Welcome to Concrete Gold Academy</h1>
              <p>
                Unlock the secrets of real estate success while putting more
                money back into your pocket. At Concrete Gold Academy, we don't
                just teach real estate—we empower you to save, grow, and thrive.
                For instance, learn how to save €321.50 in taxes every month
                while building a lucrative portfolio and mastering the skills
                needed to navigate the complex real estate landscape.
              </p>
              <p>
                Our mission is simple: to give you the tools, insights, and
                confidence to achieve financial freedom through real estate.
                Whether you're looking to invest smartly, manage properties
                effectively, or explore new market opportunities, we're here to
                guide you every step of the way.
              </p>
            </div>
          </section>

          <section className="vision-section">
            <div className="section-image-right">
              <img src={background_image_2} alt="Background 2" />
            </div>
            <div className="section-content">
              <h2>Our Story</h2>
              <p>
                Concrete Gold Academy was founded with a vision to bridge the
                gap between traditional real estate education and modern
                industry demands. Our founders, a passionate team of real estate
                experts, tech innovators, and educators, recognized the need for
                accessible, practical, and future-focused learning.
              </p>
            </div>
            <div className="section-image">
              <span>Story Image</span>
            </div>
          </section>

          <section className="redefine-section">
            <div className="section-content-redefine">
              <h2>Redefining Real Estate Education</h2>
              <p>
                Your gateway to mastering real estate investment, management,
                and consulting with practical knowledge and AI-powered tools.
              </p>
            </div>
          </section>

          <section className="values-section">
            <div className="section-image-right">
              <img src={background_image_3} alt="Background 3" />
            </div>
            <div className="section-content">
              <h2>What Sets Us Apart</h2>
              <ul>
                <li>
                  Maximize Tax Benefits: Learn strategies to reduce your tax
                  burden
                </li>
                <li>
                  Real-World Learning: Hands-on projects tailored to real estate
                  challenges
                </li>
                <li>
                  Flexible & Accessible: Learn anytime, anywhere, at your own
                  pace
                </li>
                <li>
                  Expert Guidance: Direct mentorship from seasoned professionals
                </li>
                <li>
                  Cutting-Edge Tools: Use AI-driven insights for smarter
                  decisions
                </li>
              </ul>
            </div>
            <div className="section-image">
              <span>Values Image</span>
            </div>
          </section>

          <section className="mission-section">
            <div className="section-image-right">
              <img src={background_image_1} alt="Background 1" />
            </div>
            <div className="section-content">
              <h2>What We Offer</h2>
              <ul>
                <li>Tailored Learning Paths</li>
                <li>
                  Interactive modules covering investing, property management,
                  and consulting.
                </li>
                <li>
                  Flexible learning schedules to accommodate professionals.
                </li>
                <li>Hands-On Projects</li>
                <li>Real-world tasks to apply theoretical knowledge.</li>
                <li>Projects designed to mimic industry challenges.</li>
                <li>Ongoing Support</li>
                <li>Personalized feedback from mentors.</li>
                <li>Networking opportunities within a vibrant community.</li>
                <li>AI-Driven Tools</li>
                <li>
                  Market analysis and property insights using advanced
                  technology.
                </li>
              </ul>
            </div>
          </section>

          <section className="process-section">
            <div className="section-image-right">
              <img src={background_image_4} alt="Background 3" />
            </div>

            <div className="section-content">
              <h2>Our Process</h2>
              <ul>
                <li>
                  Consultation: Understand your goals and align them with our
                  programs
                </li>
                <li>
                  Learning: Engage with expert-led modules and interactive
                  content
                </li>
                <li>Practice: Apply concepts through real-world scenarios</li>
                <li>
                  Completion: Gain certification and access lifetime support
                </li>
              </ul>
            </div>
            <div className="section-image">
              <span>Process Image</span>
            </div>
          </section>
          <section className="meet-team">
            <div className="section-content-team">
              <h2>Meet Our Team</h2>
              <p>
                Our team includes experienced professionals and innovators, all
                working toward one mission—your success.
              </p>
              <p>
                At Concrete Gold Academy, we've assembled a diverse group of experts 
                who bring together their unique perspectives and extensive experience 
                in real estate, technology, and education. Each team member contributes 
                their specialized knowledge to create a comprehensive learning experience 
                that addresses every aspect of real estate investment and management.
              </p>
              <p>
                From seasoned real estate consultants to innovative tech developers 
                and creative strategists, our team works in perfect synergy to deliver 
                cutting-edge solutions and practical insights that help our students 
                succeed in the dynamic world of real estate.
              </p>
              <h2>Our Leadership</h2>
              <h2>Driven by vision and expertise</h2>
            </div>
          </section>
          <section className="team-section">
            <div className="team-member-portrait">
              <img src={portrait_Loui}></img>
            </div>
            <div className="team-member-detail">
              <h2>LOUI SERA</h2>
              <div className="title">Founder & Real Estate Consultant</div>
              <p>
                Hi, my name is Loui and I founded the Concrete Gold Academy
                because I had a problem. My calendar used to be overloaded with
                so many consultations and phone calls with expats who came to me
                for 1 common reason: They were unhappy about their incredibly
                high tax burden and were looking for tax-beneficial investments.
                I reached the point at which I could not help all of them
                anymore and decided to filter. From now on, I only advised those
                10% who assured me that their problem of high tax burdens was so
                bad that they were urgently looking for a solution. However, due
                to time constraints, I could not help the other 90% anymore who
                were looking for an option to build their knowledge in this area
                first. That sucked. Because I actually follow the principle that
                you should only invest in what you understand. And that requires
                knowledge.
              </p>
              <p>
                When I read that book by <b>Alex Fischer</b> (a successful
                German real estate entrepreneur), I realized that I had to build
                a machine that would allow me to multiply my knowledge and make
                it accessible to everyone. And of course, the best way to do
                this is to record videos that contain everything a beginner
                needs to know to invest in real estate successfully and save
                massive amounts of taxes. And you found your way here today,
                that's great. Are you also unhappy about your incredibly high
                tax burden and want to turn taxes into assets? Become part of
                the Concrete Gold Academy and we will show you the solution. I
                would love to see you around!
              </p>
              <ul>
                <li>Real Estate Agent <b>CCI</b></li>
                <li>Property Manager <b>CCI</b></li>
              </ul>
            </div>
          </section>

          <section className="team-section">
            <div className="team-member-portrait">
              <img src={portrait_Shree}></img>
            </div>
            <div className="team-member-detail">
              <h2>SHREEKARA KULKARNI</h2>
              <div className="title">Creative Director & Product Strategist</div>
              <p>
                Hi, I'm Shreekie—a creative thinker with a passion for blending
                design, education, and business. My journey started in Interior
                Architecture and Design, where I discovered the art of creating
                spaces that truly connect with people. Over time, my curiosity
                led me to explore the world of EdTech and business strategy,
                where I realized I could use my creativity to build solutions
                that make a real difference.
              </p>
              <p>
                At the Concrete Gold Academy, I combine all these experiences. I
                shape products that help people navigate the real estate world
                with confidence while driving the strategies that keep our
                academy growing and evolving. My role is all about finding the
                balance between creativity and practicality, and I love the
                challenge of turning ideas into something impactful.
              </p>
              <p>
                For me, it's always been about empowering others—whether it's
                through a beautifully designed space, a smart strategy, or a
                learning experience that opens new doors. I'm excited to keep
                building, learning, and creating along the way.
              </p>
            </div>
          </section>

          <section className="team-section" >
            <div className="team-member-portrait">
              <img src={portrait_hari}></img>
            </div>
            <div className="team-member-detail">
              <h2>HARIKRISHNAN VENUGOPAL</h2>
              <div className="title">Lead Developer</div>
              <p>
                Hi, I'm Hari, a tech enthusiast with a passion for building reactive applications and data science. With my background in Electrical engineering, I gained the ability to manage projects. I truly kickstarted my tech career when I moved to New Zealand to study Master of Information Technology degree from The University of Waikato. I joined Concrete Gold Academy to build a platform that will help people to learn real estate investment, management and consulting with practical knowledge and AI-powered tools.
              </p>
              <p>
                From my behind the scenes knowledge I can confidently say one thing, big things are being developed at Concrete Gold Academy. This platform is truly going to leverage the power of AI and data science to truly create a transformative learning experience for our users. By integrating advanced analytics and machine learning, we aim to provide personalized learning paths, predictive insights, and real-time feedback to help our users achieve their real estate goals more efficiently and effectively. I am excited to be part of this journey and to see where it takes us.
              </p>
            </div>
          </section>
          
        </div>
      </main>
    </div>
  );
}

export default AboutUsPage;
