import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardPage.css';
import logo from './logo.png';
import dashboardBanner from './DashboardBanner.png';

function DashboardPage() {
  const navigate = useNavigate();
  const username = localStorage.getItem('username'); // Retrieve the username from local storage

  const handleLogout = async () => {
    const accessToken = localStorage.getItem('access_token'); // Get the access token

    try {
      // Send logout request
      const response = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/auth/logout', {
        method: 'POST', // Assuming the logout endpoint uses POST
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json', // Set content type if needed
        },
      });

      if (!response.ok) {
        throw new Error(`Logout failed! Status: ${response.status}`);
      }

      // Optionally, clear the access token from local storage
      localStorage.removeItem('access_token');
      localStorage.removeItem('username'); // Clear username from local storage
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <img src={logo} alt="Logo" className="dashboard-logo" onClick={() => navigate('/')} style={{cursor: 'pointer'}} />
        <nav className="nav-links">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Home
          </a>
          <a
            href="/about-us"
            onClick={(e) => {
              e.preventDefault();
              navigate("/about-us");
            }}
          >
            About Us
          </a>
          <a
            href="/faq"
            onClick={(e) => {
              e.preventDefault();
              navigate('/#faq');
            }}
          >
            FAQ
          </a>
          <a
            href="/dashboard"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard");
            }}
          >
            Dashboard
          </a>
          <a
            href="/contact"
            onClick={(e) => {
              e.preventDefault();
              navigate("/contact");
            }}
          >
            Contact Us
          </a>
        </nav>
        <div className="user-info">
          <span className="welcome-message">Welcome {username}</span>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      </header>

      <div className="dashboard-content">
        <div className="banner-overlay">
          <img src={dashboardBanner} alt="Dashboard Banner" />
        </div>
        
        <div className="content-header">
          <h1>My Library</h1>
        </div>

        <div className="content-grid">
          {[
            { 
              name: 'Real Estate Investor', 
              image: '/1.jpg', 
              description: 'Master the art of real estate investing through comprehensive coaching and practical strategies for success.',
              progress: '38%'
            }
          ].map((item) => (
            <div 
              key={item.name} 
              className={`content-item ${item.disabled ? 'disabled' : ''}`}
              onClick={() => !item.disabled && navigate('/videos')}
            >
              <img src={item.image} alt={item.name} />
              <div className="content-item-content">
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <div className="progress-info">
                  {item.progress} completed
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;